.setPassword .body {
  background-color: #e6f0d4;
  padding-top: 60px;
  padding-bottom: 60px;
}

.setPassword .page {
  background: #ffffff;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 30px;
  padding-right: 30px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}

.setPassword h1 {
  font-size: 1.8em !important;
  margin: 0 0 0.5em !important;
  font-weight: 400 !important;
  opacity: 0.7 !important;
  text-transform: capitalize !important;
}

.setPassword hr {
  border: none;
  border-bottom: 1px solid #e6f0d4;
  margin-bottom: 35px;
}

.setPassword input[type='password'] {
  background-color: #f5f9ee;
  border-color: #78a22f;
}

.setPassword button {
  background-color: #78a22f;
}

.setPassword button:hover {
  background-color: #a1bc6f;
}
