header {
  background-color: white;
  width: 100vw;
}

@media screen and (max-width: 1187px) {
  header {
    border-bottom: 1px solid #f1f1f1;
  }
}

header .main-nav {
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  z-index: 50;
  position: fixed;
}

header .main-nav .closeMenu {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 99999;
  border-radius: 5px;
  width: 42px;
  height: 42px;
  text-align: center;
  background: #fff;
  color: #1f4497;
  text-transform: none;
  font-size: 24px;
  padding: 0 6px 12px;
  font-weight: 700;
  cursor: pointer;
}

.inner {
  margin: auto;
  max-width: 1200px;
}

@media screen and (max-width: 1187px) {
  .inner {
    padding: 0 16px;
  }
}

@media screen and (max-width: 767px) {
  header .inner {
    display: none;
    padding: 0;
  }

  header .main-nav {
    background-color: rgba(31, 68, 151, 0.95);
    box-shadow: none;
  }

  header .header .inner {
    padding: 0 10px;
    display: block;
  }
}

header ul {
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: left;
  width: auto;
  list-style: none;
  border-bottom: none;
  padding-left: 0;
  padding-bottom: 0;
}

header ul li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

header .inner > ul > li > a {
  display: inline-block;
  background-color: white;
  height: auto;
  padding: 32px 12px;
  font-weight: 700;
  text-align: center;
  color: #1f4497;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-transition: background-color 0.2s ease-in-out;
}

@media screen and (max-width: 1187px) {
  header ul li {
    width: 25%;
  }

  header .inner > ul > li > a {
    width: 100%;
    padding: 15.5px 0;
  }
}

header .inner > ul > li > a:hover {
  background-color: #edf1fa;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  header ul li {
    display: block;
    width: auto;
  }

  header .inner > ul > li > a {
    background-color: transparent;
    width: 100%;
    color: white;
    padding: 18px 0 0 0;
    font-size: 18px;
  }

  header .inner > ul > li > a:hover {
    background-color: transparent;
    text-decoration: none;
  }
}

header .inner > ul > li > a.logo-link {
  display: block;
  height: auto;
  padding: 12px 28px 12px 16px;
  background-color: white;
}

@media screen and (max-width: 1187px) {
  header .inner > ul > li > a.logo-link {
    padding: 18px;
  }
}

@media screen and (max-width: 767px) {
  header .inner > ul > li > a.logo-link {
    display: inline-block;
    padding: 13.5px 0;
    width: auto;
  }
}

header .logo {
  max-width: 240px;
}

@media screen and (max-width: 767px) {
  header .logo {
    max-width: 200px;
  }
}

header .account {
  position: absolute;
  top: 22px;
  right: 0;
  bottom: 0;
  width: auto;
}

@media screen and (max-width: 1187px) {
  header .account {
    top: 30px;
    right: 13px;
  }
}

header .account .button {
  height: 42px;
  font-size: 12px;
  font-weight: 700;
  color: white;
  white-space: nowrap;
  -webkit-transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

header .account .button .name {
  float: left;
  display: block;
  background-color: #6180c7;
  padding: 12px 15px;
  -webkit-transition: background-color 0.2s ease-in-out;
}

header .account .button .icon {
  float: right;
  display: block;
  -webkit-transition: background-color 0.2s ease-in-out;
  background: url(/arrow-up.png) center no-repeat;
  background-size: 16px 9px;
  background-color: #3c5ca2;
  height: 42px;
  width: 36px;
}

header .account .button .icon.flip {
  transform: rotate(180deg);
}

header .account .button:hover .name,
header .account .button:hover .icon {
  background-color: #254692;
}

header .account .submenu {
  position: absolute;
  top: 42px;
  right: 0;
  -webkit-transition: height 0.5s ease-in-out;
  overflow: hidden;
}

header .submenu > ul > li {
  width: auto;
}

header .submenu > ul > li > button {
  display: block;
  width: 180px;
  background-color: #335299;
  text-align: right;
  margin: 0px;
  padding: 14px;
  border-radius: 0px;
  font-weight: 700;
  font-size: 14px;
  color: white;
  white-space: nowrap;
  -webkit-transition: background-color 0.2s ease-in-out;
}

header .submenu > ul > li > button:hover {
  background-color: #0c2863;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  header .submenu {
    width: 100%;
  }

  header .submenu .submenuToggle {
    color: white;
    width: 100%;
    text-align: center;
    padding: 9px 0;
    cursor: pointer;
    opacity: 0.75;
    letter-spacing: normal;
    padding-right: 30px;
  }

  header .submenu .toggleSprite {
    position: absolute;
    background: url(/arrow-up.png) center no-repeat;
    background-size: 16px 9px;
    top: 0;
    width: 36px;
    height: 42px;
    -webkit-transition: transform 0.5s ease-in-out;
  }

  header .submenu .toggleSprite.open {
    transform: rotate(180deg);
  }

  header .submenu ul {
    width: 100%;
    overflow: hidden;
    -webkit-transition: height 0.2s ease-in-out;
  }

  header .submenu ul > li {
    width: 100%;
  }

  header .submenu ul > li > button {
    background-color: transparent;
    text-align: center;
    width: 100%;
    padding: 6px 0;
  }
}
