.spinner {
  height: 60px;
  width: 60px;
  margin: 0 auto;
  position: relative;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(134, 142, 150, 0.15);
  border-right: 6px solid rgba(134, 142, 150, 0.15);
  border-bottom: 6px solid rgba(134, 142, 150, 0.15);
  border-top: 6px solid rgba(134, 142, 150, 1);
  border-radius: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
