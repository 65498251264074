body {
  background-color: 'white';
  font-family: brandon-grotesque;
  color: #555;
}

h1 {
  font-family: brandon-grotesque;
  text-transform: uppercase;
  font-size: 48px;
  letter-spacing: 2px;
  margin-top: 0;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 32px;
}

h5 {
  font-weight: 400;
  font-size: 1.5em;
  text-transform: none;
  letter-spacing: 0;
  font-style: italic;
  margin-right: 1em;
}

h6 {
  font-weight: 400;
  font-size: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  font-style: italic;
  margin-right: 1em;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 36px;
  }
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

@font-face {
  font-family: 'Gotham';
  src: local('Gotham'),
    url(../../assets/fonts/Gotham/Gotham-Book.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: local('Gotham'),
    url(../../assets/fonts/Gotham/Gotham-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: local('Gotham'),
    url(../../assets/fonts/Gotham/Gotham-Medium.otf) format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: local('Gotham'),
    url(../../assets/fonts/Gotham/Gotham-BookIta.otf) format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: local('Brandon Grotesque'),
    url(../../assets/fonts/Brandon-Grotesque/Brandon_reg.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Brandon Grotesque';
  src: local('Brandon Grotesque'),
    url(../../assets/fonts/Brandon-Grotesque/Brandon_bld.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Brandon Grotesque';
  src: local('Brandon Grotesque'),
    url(../../assets/fonts/Brandon-Grotesque/Brandon_med.otf) format('opentype');
  font-weight: 500;
  font-style: normal;
}
