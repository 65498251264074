#email-sent-box {
  background: #1f4497;
  color: white;
  border-radius: 10px;
  padding: 15px;
  max-width: 310px;
  margin: 12px 0 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
#email-sent-box img {
  flex: 0 1 auto;
  margin-right: 15px;
}
#email-sent-box div {
  flex: 1;
}
