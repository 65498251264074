/* Footer */
.footer {
  background: url('../../assets/img/footer-bg.jpg') scroll no-repeat center
    bottom #0091c6;
  background-size: cover;
  color: #fff;
  padding: 2.5em 0em 2.5em 0em;
}

.footer a {
  border-bottom: none;
  color: #fff;
  font-weight: 700;
}

.footer ul {
  width: 100%;
  font-weight: 400;
  font-style: normal;
  padding-left: 0;
}

@media screen and (max-width: 767px) {
  .footer ul {
    text-align: center;
  }
}

.footer li {
  padding: 0px 5px 10px 5px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
}

@media screen and (max-width: 1187px) {
  .footer li {
    font-size: 14px;
  }
}

/* Top Footer */
.top-footer {
  width: 100%;
}

/* Nav Links */
.nav-links li {
  text-align: left;
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media screen and (max-width: 767px) {
  .nav-links li {
    display: inline-block;
    width: auto;
    padding: 0px 14px 28px 14px;
  }
}

/* Social */
.size1of3 {
  width: 33%;
  display: inline-block;
  vertical-align: top;
}

@media screen and (max-width: 767px) {
  .size1of3 {
    width: 100%;
    display: block;
    vertical-align: top;
    float: none;
  }
}

.fourths {
  width: 100%;
}

ul.fourths {
  float: left;
  padding: 0px 5px 10px 5px;
  box-sizing: border-box;
  list-style-type: none;
}

ul.fourths li {
  float: left;
  padding: 0px 5px 10px 5px;
  box-sizing: border-box;
  list-style-type: none;
  width: 25%;
}

.social a.button {
  display: block;
  background: #1f4497;
  border-radius: 5px;
  line-height: 0;
  margin-bottom: 0;
  position: static;
  padding: 1em 1.25em 1em 1.25em;
}

.social a.phone.button {
  text-align: center;
  color: #fff;
  font-size: 1.1em;
  line-height: 1;
  margin: 0px 5px 2em 5px;
  padding: 1.5em;
  white-space: nowrap;
  clear: both;
}

.social li a.twitter:hover {
  background: #55acee;
}

.social li a.facebook:hover {
  background: #3b5998;
}

.social li a.pinterest:hover {
  background: #cc2127;
}

.social li a.instagram:hover {
  background: #e7ded1;
}

.social img {
  max-width: 32px;
  margin: 0px auto 0px auto;
}

/* Legal */
.footer ul.legal {
  margin-top: 2em;
}

.legal li {
  display: block;
  font-size: 0.825em;
}

.legal li a {
  display: inline;
  color: #fff;
}

.legal li a:hover {
  background: transparent;
}

.legal img {
  max-width: 350px;
  margin: 0 auto;
  border: 0;
  height: auto;
}

.legal .disclaimer {
  font-size: 13px;
  opacity: 0.5;
  padding: 0em 1em 2em 1em;
}
