.page {
  max-width: 640px;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .page {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
