.accordion {
  width: 100%;
  -webkit-transition: height 0.5s ease-in-out;
  overflow: hidden;
}

h3.accordionTitle {
  border-top: 1px solid #e1e1e1;
  padding: 0.75em 0;
  margin: 0;
  text-transform: none;
  letter-spacing: 0;
  color: #1f4497;
  font-size: 20px;
  cursor: pointer;
}

h3.accordionTitle .accordionToggle {
  background: #ccc;
  margin-top: -5px;
  font-style: normal;
  border-radius: 5px;
  border: none;
  padding: 5px 0 0 0;
  display: block;
  float: left;
  font-size: 28px;
  color: #fff;
  line-height: 1;
  width: 35px;
  text-align: center;
  margin-right: 10px;
  -webkit-transition: -webkit-transform 0.4s ease-in;
  height: 35px;
}

h3.accordionTitle:hover .accordionToggle {
  background: #aaa;
}

h3.accordionTitle .accordionToggle.current {
  transform: rotate(45deg);
}
